export const routes = [
  {
    name: "Blog",
    route: "/blog",
  },
  {
    name: "Development",
    route: "/dev",
  },
  {
    name: "About",
    route: "/about",
  },
  {
    name: "Uses",
    route: "/uses",
  },
]
